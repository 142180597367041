<template>
<div v-if="notifications">

  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge 
          :content="counter"
          :value="counter"
          color="primary"
          overlap >
          <v-icon 
            v-bind="attrs"
            v-on="on" >
            mdi-bell-ring-outline
          </v-icon>
        </v-badge>
      </template>

      <v-card width="350">

        <v-card-actions class="pl-0 pr-3">
          <v-list-item class="grow">
            <h2 class="font-weight-normal pl-0 ml-0">
                Notifiche
            </h2>
            <v-row
              align="center"
              justify="end"
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="black"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click.prevent="markAllAsRead" >
                    <v-list-item-title >Segna tutte come lette</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item to="/settings" @click.prevent="menu = false" >
                    <v-list-item-title >Notification settings</v-list-item-title>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </v-row>
          </v-list-item>
        </v-card-actions>

        <v-divider></v-divider>

        <v-card-text v-if="notifications.length == 0">There are no notifications</v-card-text>

        <v-virtual-scroll
          :items="notifications"
          :item-height="60"
          height="400"
        >
          <template v-slot:default="{ item }">
            <v-list-item :to="item.target_url_web" @click.prevent="goToUrl(item)">
              <!-- <v-list-item-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                >
              </v-list-item-avatar> -->

              <v-list-item-content>
                <v-list-item-title>{{item.title}}</v-list-item-title>
                <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
                <!-- <v-list-item-subtitle>{{item.text.substring(0,20)}}</v-list-item-subtitle> -->
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  :class="item.isRead ? 'white--text' : 'primary--text'"
                  icon
                >
                  <v-icon small>mdi-checkbox-blank-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>

      </v-card>
    </v-menu>
</div>
  
</template>

<script>
import { mapGetters } from "vuex";
import { eventsCollection } from "@/plugins/firebase.js";

export default {
  name: "InAppNotifications",

  data(){
    return{
      notifications3: [],
      show: false,

      fav: true,
      menu: false,
      message: false,
      hints: true,

      ciao: 'panata'
    }
  },

  computed: {
    ...mapGetters({
      notifications: "notifications",
    }),
    counter(){
      let count = 0;
      count = this.notifications.filter(item => !item.isRead).length;
      return count
    },
  },

  methods: {
    goToUrl(item){
      this.markAsRead(item)
      this.menu = false
    },
    async markAsRead(item){
      let dataToSet = {
        isRead: true,
      };
      await eventsCollection
      .doc(item.id)
      .set(dataToSet, { merge: true })
    },
    markAllAsRead(){
      let unread = this.notifications.filter(item => !item.isRead)
      unread.forEach(element => {
        this.markAsRead(element)
      });
      this.menu = false
    }
  },

}
</script>

<style>

</style>