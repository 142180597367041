var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.notifications)?_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"content":_vm.counter,"value":_vm.counter,"color":"primary","overlap":""}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-bell-ring-outline ")])],1)]}}],null,false,717572457),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"width":"350"}},[_c('v-card-actions',{staticClass:"pl-0 pr-3"},[_c('v-list-item',{staticClass:"grow"},[_c('h2',{staticClass:"font-weight-normal pl-0 ml-0"},[_vm._v(" Notifiche ")]),_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,false,253076246)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.markAllAsRead($event)}}},[_c('v-list-item-title',[_vm._v("Segna tutte come lette")])],1)],1)],1)],1)],1)],1),_c('v-divider'),(_vm.notifications.length == 0)?_c('v-card-text',[_vm._v("There are no notifications")]):_vm._e(),_c('v-virtual-scroll',{attrs:{"items":_vm.notifications,"item-height":60,"height":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{attrs:{"to":item.target_url_web},on:{"click":function($event){$event.preventDefault();return _vm.goToUrl(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.text))])],1),_c('v-list-item-action',[_c('v-btn',{class:item.isRead ? 'white--text' : 'primary--text',attrs:{"icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-blank-circle")])],1)],1)],1)]}}],null,false,1700476004)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }